import * as React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_usaLow from "@amcharts/amcharts5-geodata/usaLow";

import "./styles.scss";
const UsaMap: React.FunctionComponent = () => {
  React.useEffect(() => {
    console.log("map");
    const root = am5.Root.new("map");
    const colors = am5.ColorSet.new(root, {});

    root.setThemes([am5themes_Animated.new(root)]);
    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        projection: am5map.geoAlbersUsa(),
      })
    );
    const usaSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, { geoJSON: am5geodata_usaLow })
    );

    usaSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      interactive: true,
      fill: am5.color(0xaaaaaa),
      templateField: "polygonSettings",
    });

    usaSeries.mapPolygons.template.states.create("hover", {
      fill: colors.getIndex(9),
    });

    usaSeries.mapPolygons.template.events.on("click", (ev) => {
      console.log(ev);
    });

    const stateSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, { visible: false })
    );

    stateSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      interactive: true,
      fill: am5.color(0xff0000),
    });

    stateSeries.mapPolygons.template.states.create("hover", {
      fill: colors.getIndex(9),
    });
  }, []);

  return <div id="map" className="usa-map"></div>;
};

export default UsaMap;
